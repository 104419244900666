import React, { useContext } from "react";
import "./App.css";

import { Route, Switch, Redirect, useHistory, Router } from "react-router-dom";


import HomePage from "./Page/LandingPage";
import Pooja from "./Page/PojaDetailPage/PojaDetailPage"
import Booking from "./Page/BookingPage/Booking"
import Libary from "./Page/LibraryPage/Libary";
import WhoIsPooja from "./Page/WhoIsPooja/WhoIsPooja";
import About from "./Page/AboutUs/AboutUs"
import Offering from "./Page/Offerings/Offerings"
function App() {


  return (
    <>
      <div className="" >
        <Switch>
   
          <Route exact path="/" render={() => <HomePage />} />


        </Switch>
      </div>
    </>
  );
}

export default App;
